import React from "react"
import * as Style from "./ErrorMessage.module.scss"
import Button from "../custom-button/CustomButton"
import { navigate } from "gatsby"

const ErrorMessage = ({ netError }) => {
  const isBrowser = typeof window !== "undefined"
  return (
    <div className={`${Style.errorWrapper} ${netError ? Style.show : ""}`}>
      <p className={Style.error}>Нещо се обърка</p>
      <div className={Style.btnWrapper}>
        <Button
          global={true}
          buttonHandler={() => {
            if (isBrowser) {
              window.location.href = window.location.href
            }
          }}
          style={Style.errorBtn}
        >
          Опитай отново
        </Button>
      </div>
    </div>
  )
}

export default ErrorMessage
