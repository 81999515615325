import React, { useEffect, useState } from "react"
import * as Style from "./IdeaForm.module.scss"
import usePictures from "../../custom-hooks/usePictures"
import Input from "../UI/input/Input"
import Button from "../UI/custom-button/CustomButton"
import { FaCloudUploadAlt } from "react-icons/fa"
import IdeaTextForm from "../idea-text-form/IdeaTextForm"
import Loader from "../UI/loader/Loader"
import { navigate } from "gatsby"
import Recaptcha from "react-recaptcha"
import CustomCheckbox from "../UI/custom-checkbox/CustomCheckbox"
import { Link } from "gatsby"
import CustomButton from "../UI/custom-button/CustomButton"
import { FaWindowClose } from "react-icons/fa"
import ErrorMessage from "../UI/error-message/ErrorMessage"

const IdeaForm = ({ ideaUserData, post_code, setHideBackBtn }) => {
  const {
    imagehandler,
    sendIdea,
    images,
    ideaText,
    setIdeaText,
    ideaTitle,
    setIdeaTitle,
    submit,
    loading,
    removeImage,
    success,
    notArobot,
    verify,
    recapLoader,
    termsAgree,
    setTermsAgree,
    netError,
    setIdeaBudget,
    ideaBudget,
    setIdeaWork,
    ideaWork,
  } = usePictures(ideaUserData, post_code, setHideBackBtn)

  const successHandler = () => {
    navigate("/")
    // setHideBackBtn(false)
    // setSuccess(false)
  }

  useEffect(() => {
    if (success) {
      setHideBackBtn(true)
    }
  })

  return (
    <>
      <ErrorMessage netError={netError} />
      {success && (
        <div className={Style.successMessageWrapperPaddingWrapper}>
          <div className={Style.successMessageWrapper}>
            <h2>Вашата идея беше регистрирана успешно!</h2>
            <Button
              style={Style.successBtn}
              buttonHandler={successHandler}
              global={true}
            >
              Обратно към начало
            </Button>
          </div>
        </div>
      )}

      {loading && (
        <div className={Style.loadingPaddingWrapper}>
          <div className={Style.loaderWrapper}>
            <Loader />
          </div>
        </div>
      )}

      {!loading && !success && (
        <div className={Style.idaFormWrapper}>
          <h2 className={Style.infoBox}>
            Попълнете заглавие и описание на вашата идея
          </h2>
          <IdeaTextForm
            submit={submit}
            setIdeaTitle={setIdeaTitle}
            ideaTitle={ideaTitle}
            setIdeaText={setIdeaText}
            ideaText={ideaText}
            setIdeaBudget={setIdeaBudget}
            ideaBudget={ideaBudget}
            setIdeaWork={setIdeaWork}
            ideaWork={ideaWork}
          />
          <h2 className={Style.infoBox}>
            Може да изпратите до три снимки с вашата идея, също така може и да
            не прилагате снимки
          </h2>
          <form className={Style.pictureForm} onSubmit={sendIdea}>
            <div className={Style.uploadWrapper}>
              <label htmlFor={`image-input`}>
                <FaCloudUploadAlt size={50} className={Style.uploadIcon} />
              </label>
              <Input
                style={Style.fileInput}
                id="image-input"
                type="file"
                changeHandler={imagehandler}
                accept=".png, .jpeg, .jpg"
              />
              <p
                className={Style.imageCounter}
              >{`Изображения ${images.length}/3`}</p>
            </div>
            <div className={Style.imageList}>
              {images.map((image, index) => {
                return (
                  <div className={Style.imageListWrapper} key={image?.name}>
                    <CustomButton
                      buttonHandler={removeImage.bind(this, index)}
                      type="button"
                      style={Style.removePicture}
                    >
                      <FaWindowClose size={15} color={"red"} />
                    </CustomButton>
                    <p className={Style.imageNumber}>{`${index + 1}.`}</p>
                    <p>{image?.name}</p>
                  </div>
                )
              })}
            </div>
            <div
              className={`${Style.recaptchaWrapper} ${
                !notArobot && submit ? Style.recaptchaWrapperInvalid : ""
              }`}
            >
              <Recaptcha
                sitekey="6LcugPMhAAAAAK8zuQAW4bF8leCfrAWj3Xdb0WNZ"
                render="explicit"
                onloadCallback={recapLoader}
                verifyCallback={verify}
              />
            </div>
            <div
              className={`${Style.termsFormWrapper} ${
                !termsAgree && submit ? Style.termsFormWrapperInvalid : ""
              }`}
            >
              <CustomCheckbox
                checkboxHandler={() => {
                  setTermsAgree(!termsAgree)
                }}
              />
              <div className={Style.termsLinkWrapper}>
                <p>Съгласен/а съм с</p>
                {/* <Link to="/terms" target="_blank">
                  Общите условия
                </Link> */}
                <a href="/terms" target="_blank">
                  условията
                </a>
                {/* <p>на Програмата</p> */}
              </div>
            </div>

            <Button style={Style.pictureBtn} global={true} type="submit">
              {images.length === 0 ? "Изпрати без снимки" : "Изпрати идея"}
            </Button>
          </form>
        </div>
      )}
    </>
  )
}

export default IdeaForm

//   const [images, setImages] = useState([])
//   console.log(images)

//   const sendIdea = () => {
//     const formdata = new FormData()
//     formdata.append("title", `test`)

//     if (images.length !== 0) {
//       images.forEach(ele => {
//         formdata.append("images", ele)
//       })
//     }

//     const requestOptions = {
//       method: "POST",
//       body: formdata,
//       redirect: "follow",
//     }

//     fetch(`https://halobg.herokuapp.com/api/addidea`, requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         console.log(result)
//       })
//       .catch(error => console.log("error", error))
//       .finally(() => {})
//   }
