// extracted by mini-css-extract-plugin
export var formBtn = "IdeaDataForm-module--formBtn--3P2wd";
export var formLoading = "IdeaDataForm-module--formLoading--8hRnh";
export var formMainContainer = "IdeaDataForm-module--formMainContainer--YPKrV";
export var inputWrapper = "IdeaDataForm-module--inputWrapper--AAmhG";
export var invalidPar = "IdeaDataForm-module--invalidPar--3Vj-c";
export var invalidVoteInput = "IdeaDataForm-module--invalidVoteInput--fR-gw";
export var radio = "IdeaDataForm-module--radio--uVXok";
export var slideDown = "IdeaDataForm-module--slide-down--qv4pt";
export var voteInput = "IdeaDataForm-module--voteInput--PIrsZ";
export var voterForm = "IdeaDataForm-module--voterForm--xutF2";
export var voterFormArea = "IdeaDataForm-module--voterFormArea--a8szA";