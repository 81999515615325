import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import IdeaForm from "../components/idea-form/IdeaForm"
import DropDown from "../components/UI/drop-down/DropDown"
import * as Style from "./idea.module.scss"
import IdeaDataForm from "../components/idea-data-form/IdeaDataForm"
import Button from "../components/UI/custom-button/CustomButton"
import { FaArrowLeft } from "react-icons/fa"
import Layout from "../components/layout/Layout"
import { navigate } from "gatsby"

const query = graphql`
  query {
    allStrapiArea {
      nodes {
        id
        area_bg
        area_en
        post_code
        school
      }
    }
  }
`
// const initialPlaceholder = "Изберете населено място"
const initialPlaceholder = "Изберете училище"
const Idea = () => {
  const [placeholder, setPlaceholder] = useState(initialPlaceholder)
  const [area, setArea] = useState(null)
  const [formArea, setFormArea] = useState(null)
  const [ideaUserData, setIdeaUserData] = useState(null)
  const [hideBackBtn, setHideBackBtn] = useState(false)

  const data = useStaticQuery(query)
  const areaData = data.allStrapiArea

  useEffect(() => {
    if (area) {
      const areaInfo = areaData.nodes.filter(item => {
        return item.area_en === area
      })
      setFormArea({
        area_en: areaInfo[0].area_en,
        area_bg: areaInfo[0].area_bg,
        post_code: areaInfo[0].post_code,
        school: areaInfo[0].school,
      })
    }
  }, [area])

  const backToStepOneHandler = () => {
    setPlaceholder(initialPlaceholder)
    setArea(null)
    setFormArea(null)
  }

  const backToStepTwoHandler = () => {
    setIdeaUserData(null)
  }

  // useEffect(() => {
  //   navigate("/")
  // }, [])

  // return null

  return (
    <Layout>
      <div className={`${Style.ideaBgWrapper} main-container`}>
        {placeholder === initialPlaceholder && (
          <div className={Style.stepOne}>
            <h2>
              За да започнете подаване на идея, <br /> моля първо да изберете
              населено място.
            </h2>
            <DropDown
              areaData={areaData}
              placeholder={placeholder}
              setPlaceholder={setPlaceholder}
              setArea={setArea}
            />
          </div>
        )}

        {placeholder !== initialPlaceholder && formArea && !ideaUserData && (
          <div className={Style.stepTwo}>
            <p
              className={Style.selectiontext}
            >{`Вие избрахте ${placeholder}`}</p>

            <h2>
              За да продължите, <br /> моля попълнете формата
            </h2>

            <IdeaDataForm
              setIdeaUserData={setIdeaUserData}
              post_code={formArea.post_code}
              area_bg={formArea.area_bg}
              area_en={formArea.area_en}
              school={formArea.school}
            />

            <div className={Style.backBtn}>
              <Button
                buttonHandler={backToStepOneHandler}
                style={Style.backArrow}
                global={true}
              >
                <FaArrowLeft className={Style.backArrowIcon} />
              </Button>
            </div>
          </div>
        )}

        {ideaUserData && (
          <div className={Style.stepThree}>
            <IdeaForm
              ideaUserData={ideaUserData}
              post_code={formArea.post_code}
              setHideBackBtn={setHideBackBtn}
            />
            {!hideBackBtn && (
              <div className={Style.backBtn}>
                <Button
                  buttonHandler={backToStepTwoHandler}
                  style={Style.backArrow}
                  global={true}
                >
                  <FaArrowLeft className={Style.backArrowIcon} />
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Idea
