import { useState } from "react"

const usePictures = (ideaUserData, post_code, setHideBackBtn) => {
  const [images, setImages] = useState([])
  const [ideaTitle, setIdeaTitle] = useState("")
  const [ideaText, setIdeaText] = useState("")
  const [ideaBudget, setIdeaBudget] = useState("")
  const [ideaWork, setIdeaWork] = useState("")
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [notArobot, setNotArobot] = useState(false)
  const [termsAgree, setTermsAgree] = useState(false)
  const [netError, setNetError] = useState(false)

  const recapLoader = () => {
    // console.log("loaded")
  }

  const verify = response => {
    if (response) {
      const formdata = new FormData()
      formdata.append("token", response)

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(`https://halobg.herokuapp.com/api/recap`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            setNotArobot(true)
          }
        })
        .catch(error => console.log("error", error))
        .finally(() => {})
    }
  }

  const imagehandler = e => {
    if (images.length < 3) {
      setImages(state => {
        return [...state, e.target.files[0]]
      })
    }
  }

  const removeImage = imageIndex => {
    const newImages = images.filter((item, index) => {
      return index !== imageIndex
    })
    setImages(newImages)
  }

  const resetData = () => {
    setImages([])
    setIdeaTitle("")
    setIdeaText("")
    setSubmit(null)
    setNotArobot(false)
    setTermsAgree(false)
    setNetError(false)
  }

  const sendIdea = event => {
    event.preventDefault()
    setSubmit(true)

    if (
      ideaUserData &&
      post_code &&
      ideaTitle.trim() &&
      ideaText.trim() &&
      ideaBudget.trim() &&
      ideaWork.trim() &&
      notArobot &&
      termsAgree
    ) {
      setHideBackBtn(true)
      setLoading(true)
      const formdata = new FormData()
      formdata.append("title", ideaTitle)
      formdata.append("initiative_text", ideaText)
      formdata.append("initiative_budget", ideaBudget)
      formdata.append("initiative_work", ideaWork)
      formdata.append("tel", ideaUserData.tel)
      formdata.append("name", ideaUserData.name)
      formdata.append("email", ideaUserData.email)
      formdata.append("area_en", ideaUserData.area_en)
      formdata.append("area_bg", ideaUserData.area_bg)
      formdata.append("post_code", post_code)
      formdata.append("school", ideaUserData.school)
      formdata.append("submittedByRole", ideaUserData.submittedByRole)

      if (images.length !== 0) {
        images.forEach(ele => {
          formdata.append("images", ele)
        })
      }

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(`https://halobg.herokuapp.com/api/addidea`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if (result === "success") {
            setSuccess(true)
          } else {
            // alert("Нещо се обърка")
            setNetError(true)
          }
        })
        .catch(error => console.log("error", error))
        .finally(() => {
          resetData()
          setLoading(false)
        })
    }
  }

  return {
    imagehandler: imagehandler,
    sendIdea: sendIdea,
    images: images,
    setIdeaTitle: setIdeaTitle,
    ideaTitle: ideaTitle,
    setIdeaText: setIdeaText,
    ideaText: ideaText,
    submit: submit,
    loading: loading,
    setSuccess: setSuccess,
    success: success,
    notArobot: notArobot,
    verify: verify,
    recapLoader: recapLoader,
    termsAgree: termsAgree,
    setTermsAgree: setTermsAgree,
    removeImage: removeImage,
    netError: netError,
    setIdeaBudget,
    ideaBudget,
    setIdeaWork,
    ideaWork,
  }
}

export default usePictures
