import React from "react"
import * as Style from "./CustomCheckbox.module.scss"

const CustomCheckbox = ({ checkboxHandler }) => {
  return (
    <div className={Style.checkboxWrapper}>
      <label className={Style.container}>
        <input onClick={checkboxHandler} type="checkbox" />
        <span className={Style.checkmark} />
      </label>
    </div>
  )
}

export default CustomCheckbox
