import React, { useState } from "react"
import * as Style from "./IdeaDataForm.module.scss"
import Button from "../UI/custom-button/CustomButton"
import Input from "../UI/input/Input"
import useIdea from "../../custom-hooks/useIdea"
import CustomRadio from "../UI/custom-radio/CustomRadio"
// import { FaWindowClose } from "react-icons/fa"

const IdeaDataForm = ({
  area_bg,
  area_en,
  post_code,
  school,
  setIdeaUserData,
}) => {
  const {
    inputHandler,
    formHandler,
    resetData,
    inputState,
    submit,
    loading,
    selectedRadioValue,
    setSelectedRadioValue,
  } = useIdea(area_bg, area_en, school, setIdeaUserData)

  return (
    <div className={Style.formMainContainer}>
      <form
        onSubmit={formHandler}
        className={`${Style.voterForm} ${loading ? Style.formLoading : ""}`}
      >
        <div className={Style.voterFormArea}>
          <p>{`Моята идея е за ${school} - ${
            Number(post_code) !== 6300 ? "с." : "гр."
          } ${area_bg}`}</p>
          {/* <p>{`п.к. ${post_code} `}</p> */}
        </div>

        <div className={Style.inputWrapper}>
          <Input
            data="name"
            inputValue={inputState.name.val}
            inputHandler={inputHandler}
            placeholder={"име и фамилия"}
            style={`${Style.voteInput} ${
              !inputState.name.valid && submit ? Style.invalidVoteInput : ""
            }`}
          />
          {!inputState.name.valid && submit && (
            <p className={Style.invalidPar}>Невалидно име</p>
          )}
        </div>

        <div className={Style.inputWrapper}>
          <Input
            data="tel"
            inputValue={inputState.tel.val}
            inputHandler={inputHandler}
            type="number"
            placeholder={"Телефонен номер"}
            style={`${Style.voteInput} ${
              !inputState.tel.valid && submit ? Style.invalidVoteInput : ""
            }`}
          />
          {!inputState.tel.valid && submit && (
            <p className={Style.invalidPar}>Невалиден телефонен номер</p>
          )}
        </div>

        <div className={Style.inputWrapper}>
          <Input
            data="email"
            inputValue={inputState.email.val}
            inputHandler={inputHandler}
            style={Style.voteInput}
            placeholder={"E-mail/Не е задължителен"}

            // style={`${Style.voteInput} ${
            //   !inputState.email.valid && submit ? Style.invalidVoteInput : ""
            // }`}
          />
          {/* {!inputState.email.valid && submit && (
            <p className={Style.invalidPar}>Невалиден E-mail</p>
          )} */}
        </div>

        <div className={Style.radio}>
          <CustomRadio
            selectedRadioValue={selectedRadioValue}
            setSelectedRadioValue={setSelectedRadioValue}
            title={"Вие сте:"}
            values={[
              { en: "Teacher", bg: "Учител" },
              { en: "Student", bg: "Ученик" },
            ]}
            isInvalid={!selectedRadioValue && submit}
          />

          {!selectedRadioValue && submit && (
            <p className={Style.invalidPar}>Моля изберете</p>
          )}
        </div>

        <Button global={true} style={Style.formBtn}>
          Следваща стъпка
        </Button>
      </form>
    </div>
  )
}

export default IdeaDataForm
