import React from "react"
import * as Style from "./IdeaTextForm.module.scss"
import Input from "../UI/input/Input"

const IdeaTextForm = ({
  setIdeaTitle,
  setIdeaText,
  ideaTitle,
  ideaText,
  setIdeaBudget,
  ideaBudget,
  setIdeaWork,
  ideaWork,
  submit,
}) => {
  return (
    <div className={Style.textWrapper}>
      {/* <Input
        inputValue={ideaTitle}
        changeHandler={e => {
          setIdeaTitle(e.target.value)
        }}
        placeholder="Заглавие на идеята"
        style={`${Style.ideaTitleInput} ${
          submit && !ideaTitle.trim() ? Style.ideaTitleInputInvalid : ""
        }`}
      /> */}

      <textarea
        value={ideaTitle}
        onChange={e => {
          setIdeaTitle(e.target.value)
        }}
        placeholder="Напишете заглавие - кратко име, което ясно да представя идеята Ви.Не е необходимо да включвате в заглавието името на населеното място, за което се отнася идеята, тъй като то автоматично ще се визуализира."
        className={`${Style.mainTextInput} ${Style.textAreaTitle} ${
          submit && !ideaText.trim() ? Style.mainTextInputInvalid : ""
        }`}
      ></textarea>

      <textarea
        value={ideaText}
        onChange={e => {
          setIdeaText(e.target.value)
        }}
        // placeholder="Опишете вашата идея тук.."
        placeholder="В следващото поле опишете какъв проблем искате да разрешите, какво конкретно искате да се извърши. Опишете стъпка по стъпка дейностите така, както ги планирате. Напишете как Вашата идея ще допринесе за развитието на училището или населеното място, кой ще се ползва от постигнатите резултати. Споделете и всякаква друга допълнителна информация, която може да е от полза – например за колко време очаквате да се изпълни (отбележете в дни или месеци), изисква ли се издаване на разрешително /или друг документ/ или съгласуване с институция преди практическото извършване на дейностите. Имайте предвид, че това поле ще бъде публикувано в онлайн платформата за гласуване."
        className={`${Style.mainTextInput} ${
          submit && !ideaText.trim() ? Style.mainTextInputInvalid : ""
        }`}
      ></textarea>
      <textarea
        value={ideaBudget}
        onChange={e => {
          setIdeaBudget(e.target.value)
        }}
        // placeholder="Опишете разходите, нужни за реализиране на вашата идея. Максималният допустим размер е 3000 лв. Средствата не могат да се използват за възнаграждения!"
        placeholder="Напишете колко средства са необходими за реализиране на идеята, като опишете нужните материали/ услуги. Тази информация няма да се визуализира на етап гласуване, но ще се използва за оценка на допустимостта на подадената идея и при реализирането й, ако бъде избрана на етапа с гласуването. Обърнете внимание, че идеи за граждански инициативи, които надхвърлят предварително обявения максимален бюджет от 800 лв няма да бъдат допуснати до следващ етап на ПГБ."
        className={`${Style.mainTextInput} ${
          submit && !ideaBudget.trim() ? Style.mainTextInputInvalid : ""
        }`}
      ></textarea>
      <textarea
        value={ideaWork}
        onChange={e => {
          setIdeaWork(e.target.value)
        }}
        // placeholder="Колко доброволци са нужни за реализиране на инициативата и каква част от тях имате готовност да организирате/ да осигурите тяхното участие"
        placeholder="Дайте информация какво може да се изпълни с доброволен труд и колко ученици и възрастни са необходими, за да се изпълни гражданската инициатива. Бъдете искрени! Тази информация няма да се публикува, но ще се използва, ако вашата идея бъде избрана за реализиране."
        className={`${Style.mainTextInput} ${
          submit && !ideaWork.trim() ? Style.mainTextInputInvalid : ""
        }`}
      ></textarea>
    </div>
  )
}

export default IdeaTextForm

// style={submit && !ideaTitle ? Style.ideaTitleInputInvalid  Style.ideaTitleInput}
