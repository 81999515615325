import React from "react"
import * as Style from "./CustomRadio.module.scss"

const CustomRadio = ({
  title,
  values,
  selectedRadioValue,
  setSelectedRadioValue,
  isInvalid,
}) => {
  const radioBtns = values.map(value => {
    return (
      <div
        key={value.en}
        onClick={() => setSelectedRadioValue(value)}
        className={Style.radioWrapper}
      >
        <div
          className={`${Style.radio} ${
            value?.en === selectedRadioValue?.en ? Style.selected : ""
          } ${isInvalid ? Style.invalid : ""}`}
        />
        <p className={Style.radioText}>{value?.bg}</p>
      </div>
    )
  })

  return (
    <div className={Style.wrapper}>
      <p className={Style.title}>{title}</p>
      <div className={Style.btnWrapper}>{radioBtns}</div>
    </div>
  )
}

export default CustomRadio
