import { useReducer, useState } from "react"

const checkName = /^[a-zA-Z \u{0400}-\u{04FF}\s]+$/u
const checkForEemil =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const inputReducer = (state, action) => {
  if (action.type === "NAME") {
    return {
      name: {
        val: action.val,
        valid:
          action.val.match(checkName) && action.val.trim().length !== 0
            ? true
            : false,
      },
      email: {
        val: state.email.val,
        valid: state.email.valid,
      },
      tel: {
        val: state.tel.val,
        valid: state.tel.valid,
      },
    }
  }

  if (action.type === "EMAIL") {
    return {
      name: {
        val: state.name.val,
        valid: state.name.valid,
      },
      email: {
        val: action.val,
        valid:
          action.val.match(checkForEemil) && action.val.trim().length !== 0
            ? true
            : false,
      },
      tel: {
        val: state.tel.val,
        valid: state.tel.valid,
      },
    }
  }

  if (action.type === "TEL") {
    return {
      name: {
        val: state.name.val,
        valid: state.name.valid,
      },
      email: {
        val: state.email.val,
        valid: state.email.valid,
      },
      tel: {
        val: action.val,
        valid: action.val.length > 6 && action.val.length < 11 ? true : false,
      },
    }
  }

  return {
    name: {
      val: "",
      valid: true,
    },
    email: {
      val: "",
      valid: true,
    },
    tel: {
      val: "",
      valid: true,
    },
  }
}

const useIdea = (area_bg, area_en, school, setIdeaUserData) => {
  const [inputState, dispatchInput] = useReducer(inputReducer, {
    name: {
      val: "",
      valid: true,
    },
    email: {
      val: "",
      valid: true,
    },
    tel: {
      val: "",
      valid: true,
    },
  })

  const [submit, setSubmit] = useState(false)
  const [selectedRadioValue, setSelectedRadioValue] = useState(null)

  const inputHandler = event => {
    if (event.target.dataset.id === "name") {
      dispatchInput({ type: "NAME", val: event.target.value })
    } else if (event.target.dataset.id === "email") {
      dispatchInput({ type: "EMAIL", val: event.target.value })
    } else if (event.target.dataset.id === "tel") {
      dispatchInput({ type: "TEL", val: event.target.value })
    }
  }

  const formHandler = event => {
    event.preventDefault()
    setSubmit(true)

    if (
      inputState.name.valid &&
      inputState.name.val &&
      // inputState.email.valid &&
      // inputState.email.val &&
      inputState.tel.valid &&
      inputState.tel.val
    ) {
      const userIdea = {
        name: inputState.name.val.toString(),
        email: inputState.email.valid
          ? inputState.email.val.toString()
          : "no email entered",
        tel: inputState.tel.val.toString(),
        area_bg: area_bg,
        area_en: area_en,
        school: school,
        submittedByRole: selectedRadioValue?.en,
      }
      setIdeaUserData(userIdea)
    } else {
      dispatchInput({ type: "NAME", val: inputState.name.val })
      dispatchInput({ type: "EMAIL", val: inputState.email.val })
      dispatchInput({ type: "TEL", val: inputState.tel.val })
    }
  }

  const resetData = () => {
    dispatchInput({ type: "RESET_INPUT" })
    setSubmit(false)
  }

  return {
    inputHandler: inputHandler,
    formHandler: formHandler,
    resetData: resetData,
    inputState: inputState,
    submit: submit,
    selectedRadioValue,
    setSelectedRadioValue,
  }
}

export default useIdea
